import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import Image from '@/web/views/components/image/image';

import mini from '@/shared/icons/car-types/mini-icon.svg';
import hatchback from '@/shared/icons/car-types/hatchback-icon.svg';
import sedan from '@/shared/icons/car-types/sedan-icon.svg';
import stationcar from '@/shared/icons/car-types/stationcar-icon.svg';
import mpv from '@/shared/icons/car-types/mpv-icon.svg';
import suv from '@/shared/icons/car-types/suv-icon.svg';
import cabriolet from '@/shared/icons/car-types/cabriolet-icon.svg';
import coupe from '@/shared/icons/car-types/coupe-icon.svg';
import el from '@/shared/icons/car-types/el-icon.svg';
import { useTranslation } from 'react-i18next';
import { PadBox } from '../containers/pad-box';

interface Icon {
  src: string;
  height: number;
  width: number;
}

interface CarType {
  icon: Icon;
  url: string;
}

const itemSlices: CarType[][] = [
  [
    {
      url: '/brugte-biler/biltype-stationcar',
      icon: { src: stationcar.src, height: 71, width: 78 },
    },
    {
      url: '/brugte-biler/biltype-mpv',
      icon: { src: mpv.src, height: 71, width: 78 },
    },
    {
      url: '/brugte-biler/biltype-suv',
      icon: { src: suv.src, height: 70, width: 78 },
    },
    {
      url: '/brugte-biler/biltype-cabriolet',
      icon: { src: cabriolet.src, height: 70, width: 78 },
    },
    {
      url: '/brugte-biler/biltype-coupe',
      icon: { src: coupe.src, height: 71, width: 78 },
    },
    {
      url: '/brugte-biler/braendstoftype-el',
      icon: { src: el.src, height: 66, width: 65 },
    },
    {
      url: '/brugte-biler/biltype-hatchback',
      icon: { src: hatchback.src, height: 70, width: 78 },
    },
    {
      url: '/brugte-biler/biltype-sedan',
      icon: { src: sedan.src, height: 71, width: 78 },
    },
  ],
];

function FindCarAfterTypeSection() {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant={'h2'}>{t('Labels.FindCarByType')} </Typography>
      <PadBox>
        {/* HACK: marginBottom={'-15px'}, cant control next/image style; add padding below image when display:block is not set  */}
        <Grid container spacing={0} rowGap={'29px'} marginBottom={'-15px'}>
          {itemSlices.map((slice, index) => {
            return (
              <Grid key={index} item xs={12} md={12} marginX={'auto'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  flexWrap={'wrap'}
                  columnGap={'10px'}
                  width={'100%'}
                  alignContent={'flex-start'}
                  justifyContent={'space-around'}>
                  {slice.map((item, innerIndex) => {
                    return (
                      <Box key={`${index}-${innerIndex}`} minWidth={'80px'}>
                        <Link href={item.url}>
                          <Image
                            alt={item.url}
                            src={item.icon.src}
                            height={item.icon.height}
                            width={item.icon.width}
                          />
                        </Link>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </PadBox>
    </>
  );
}

export default FindCarAfterTypeSection;
