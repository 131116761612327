import React, { FC, useEffect, useState } from 'react';
import logo from './logo.png';
import { Box, Button, Grid, Typography } from '@mui/material';
import { OpenExternalLink } from '@/web/util/helpers/open-external-link';
import Image from '@/web/views/components/image/image';
import { Colors } from '@/shared/util/colors';
import { TrackEvent } from '@/shared/util/gtm';
import { useIntersectionObserver } from '@uidotdev/usehooks';

const link =
  'https://www.dccenergi.dk/produkt/lej-ladeboks/?utm_source=Banner_tjekbil&utm_medium=Bilhandel&utm_campaign=banner';

const VipCard: FC = () => {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });
  const [hasBeenInView, setHasBeenInView] = useState<boolean>(false);

  const handleOnClick = () => {
    TrackEvent('dcc_klikket');
    OpenExternalLink(link);
  };

  useEffect(() => {
    if (entry?.isIntersecting && hasBeenInView === false) {
      setHasBeenInView(true);
      TrackEvent('dcc_vist');
    }
  }, [entry?.isIntersecting, hasBeenInView]);

  return (
    <>
      <Box
        ref={ref}
        id="dcc-vip-card"
        position={'relative'}
        style={{ cursor: 'pointer' }}
        display={'flex'}
        alignItems={'flex-start'}
        justifyContent={'center'}
        borderRadius={'10px'}
        onClick={handleOnClick}
        height={'100%'}>
        <Image
          alt="logo"
          style={{
            borderRadius: '10px',
            width: '100%',
          }}
          src={logo.src}
        />
      </Box>
    </>
  );
};

export default VipCard;
